import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import UserStorage from 'storage/userStorage';
import axios from 'axios';
import { getUrl } from 'service/config/serviceUrls';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
const axiosInstance = axios.create({
  baseURL: `${getUrl()}`,
});

const ConfigureAxios = ({ children }) => {
  const [dialogMessage, setDialogMessage] = useState(null);
  const [displayDialog, setDisplayDialog] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  const userStorage = useMemo(() => new UserStorage(), []);

  const responseInterceptor = response => response;

  const errorInterceptor = useCallback(
    async error => {
      if (error.response?.status === 401) {
        const tokenResponse = await getAccessTokenSilently({
          detailedResponse: true,
        });

        if (tokenResponse && tokenResponse.id_token) {
          const decodedToken = jwt_decode(tokenResponse.id_token);
          // Convert token expiration date in seconds to milliseconds
          const tokenExpireDate = decodedToken.exp * 1000;
          // Number of milliseconds in one minute
          const ONE_MINUTE = 1000 * 60;

          // If token expired at least one minute ago, display a dialog for
          // clearing the user storage and refreshing the page.
          // This takes the user to the login page (see AppWrapper)
          if (tokenExpireDate <= Date.now() - ONE_MINUTE) {
            setDialogMessage('Your session expired');
            setDisplayDialog(true);
          }
        }
      }

      if (
        error.response?.status === 403 &&
        error.response?.data === 'User inactive or blocked'
      ) {
        setDialogMessage(
          'Your account has been marked as Inactive or Blocked.',
        );
        setDisplayDialog(true);
      }

      return Promise.reject(error);
    },
    [userStorage],
  );

  const requestInterceptor = useCallback(
    async config => {
      try {
        // Retrieve token using Auth0's getAccessTokenSilently method
        const tokenResponse = await getAccessTokenSilently({
          detailedResponse: true,
        });

        if (tokenResponse && tokenResponse.id_token) {
          console.log('axios adding token');
          config.headers['Authorization'] = `Bearer ${tokenResponse.id_token}`;
        }
        return config;
      } catch (err) {
        console.error('Error retrieving access token:', err);
        return config;
      }
    },
    [getAccessTokenSilently],
  );

  useEffect(() => {
    const interceptorsId = axiosInstance.interceptors.response.use(
      responseInterceptor,
      errorInterceptor,
    );
    const requestInterceptorId =
      axiosInstance.interceptors.request.use(requestInterceptor);

    return () => {
      axiosInstance.interceptors.response.eject(interceptorsId);
      axiosInstance.interceptors.request.eject(requestInterceptorId);
    };
  }, [errorInterceptor, requestInterceptor]);

  const confirmationDialogFooter = (
    <Button
      label="Go to login"
      className="btn-primary rounded-3 w-100 m-3 mt-4"
      onClick={() => {
        userStorage.clear();
        navigate(0);
        setDisplayDialog(false);
      }}
    />
  );

  return (
    <>
      <Dialog
        visible={displayDialog}
        closable={false}
        modal
        footer={confirmationDialogFooter}
        className="w-400 session-modal"
      >
        <div className="dialog-delete-wire-second-text px-4">
          <div className="align-items-center justify-content-center">
            <img src="/assets/icons/alertSession.svg" alt="Session Close" />
            <div className="p-dialog-title py-3">{dialogMessage}</div>
            <p>
              For security purposes, your current session has expired. Please
              log in again to continue
            </p>
          </div>
        </div>
      </Dialog>
      {children}
    </>
  );
};

export default axiosInstance;
export { ConfigureAxios };
