import { useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { useUser } from 'hooks/useUser';
import ProfileEdit from 'components/Profile/ProfileEdit';
import ChangePassword from 'components/Profile/ChangePassword';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { FileUpload } from 'primereact/fileupload';
import UserService from 'service/UserService';
import { verticleLineIcon } from 'components/Icons/VerticleLine';
import { arrowDownIcon } from 'components/Icons/ArrowDown';
import { useToast } from 'hooks/useToast';
import useLoader from 'hooks/useLoader';

const AppTopbar = props => {
  const [displayProfileEdit, setDisplayProfileEdit] = useState(false);
  const [displayPasswordReset, setDisplayPasswordReset] = useState(false);
  const toast = useToast();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const { userData, clear, setUserData } = useUser();
  const rightMenu = useRef(null);
  const fileUploadRef = useRef(null);
  const [profilePicDialogVisible, setProfilePicDialogVisible] = useState(false);
  const { setLoading } = useLoader();
  const itemRenderer = item => (
    <div onClick={item.command} className="p-menuitem-content">
      <a className="flex align-items-center p-menuitem-link">
        <span className={item.icon} />
        <span className="mx-2">{item.label}</span>
        {item.shortcut && (
          <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
            {item.shortcut}
          </span>
        )}
      </a>
    </div>
  );
  const getLabel = userData.name
    ? userData.name.charAt(0) + userData.name?.split(' ')[1]?.charAt(0)
    : '';
  const userService = new UserService();

  const items = [
    {
      template: (_, options) => (
        <div
          className={classNames(
            options.className,
            'w-full onhover  cursor-pointer  flex align-items-center p-4 text-color  border-noround',
          )}
          style={{ cursor: 'default' }}
          onClick={() => {
            setProfilePicDialogVisible(true);
          }}
        >
          <div className="position relative">
            <Avatar
              image={userData?.profilePicture || null} // Use profile picture if available
              label={getLabel}
              size="large"
              className="mr-2 shapestyle"
              shape="circle"
            />
            <span className="position-absolute shadow-sm cursor-pointer userpic">
              <img src="/assets/icons/editIcon.svg" alt="editIcon" />
            </span>
          </div>
          <div className="flex flex-column align">
            <span className="font-bold">{userData.name}</span>
            <span className="text-sm">{userData.email}</span>
          </div>
        </div>
      ),
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      template: itemRenderer,
      command: () => clear(),
    },
  ];

  const onUpload = () => {
    if (selectedPhoto) {
      setLoading(true);
      // if file size exceeds 1 mb then reduce the quality of the image to less than 1 mb
      const image = new Image();
      image.src = selectedPhoto.objectURL;
      image.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 250;
        canvas.height = 250;
        ctx.drawImage(image, 0, 0, 250, 250);
        ctx.canvas.toBlob(
          blob => {
            const file = new File([blob], 'profilePic', {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            userService
              .updateProfilePhoto(file)
              .then(res => {
                setProfilePicDialogVisible(false);
                setSelectedPhoto(null);
                setUserData({
                  ...userData,
                  profilePicture: res.data.profilePicture,
                });
                toast.current.show({
                  severity: 'success',
                  summary: 'Success',
                  detail: 'Profile picture uploaded successfully',
                });
                setLoading(false);
              })
              .catch(error => {
                setLoading(false);
                toast.current.show({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'Error uploading profile picture',
                });
              });
          },
          'image/jpeg',
          1,
        );
      };
    }
  };

  return (
    <>
      <Dialog
        visible={displayProfileEdit}
        style={{ width: '60vw' }}
        header="Edit Profile"
        onHide={() => setDisplayProfileEdit(false)}
      >
        <ProfileEdit onProfileEdited={() => setDisplayProfileEdit(false)} />
      </Dialog>

      <Dialog
        visible={displayPasswordReset}
        style={{ width: '60vw' }}
        header="Change password"
        onHide={() => setDisplayPasswordReset(false)}
      >
        <ChangePassword
          onPasswordChanged={() => setDisplayPasswordReset(false)}
        />
      </Dialog>
      <Dialog
        closable={true}
        visible={profilePicDialogVisible}
        style={{ width: '25vw' }}
        className="promodal"
        onHide={() => {
          setProfilePicDialogVisible(false);
          setSelectedPhoto(null);
        }}
      >
        <div className="text-center">
          <div className="p-4 position-relative">
            <a
              onClick={() => {
                setProfilePicDialogVisible(false);
                setSelectedPhoto(null);
              }}
            >
              <img
                src="/assets/icons/closem.svg"
                className="position-absolute end-0 mt-0 m-4 cursor-pointer"
                alt="Close"
              />
            </a>
            <h3 className="mb-3 mt-0 d-block fw-bold text-dark fs-4">
              Profile Picture
            </h3>
            <p>
              A picture helps people recognize you and lets you know when you’re
              signed in to your account
            </p>
            <div className="py-4">
              <Avatar
                image={selectedPhoto?.objectURL ?? userData?.profilePicture}
                onClick={() => fileUploadRef.current?.getInput()?.click()}
                label={getLabel}
                className="mr-2 w7rem shapestyle"
                shape="circle"
              />
            </div>
            <div className="d-flex gap-3 mt-5">
              {!selectedPhoto ? (
                <>
                  <button
                    className="btn_outline w-50"
                    onClick={() => setProfilePicDialogVisible(false)}
                  >
                    Close
                  </button>
                  <FileUpload
                    onSelect={e => {
                      setSelectedPhoto(e.files[0]);
                    }}
                    onUpload={e => console.log('uploaded', e)}
                    chooseLabel="Update"
                    className="w-50 d-block btn-primary"
                    ref={fileUploadRef}
                    chooseOptions={{
                      iconOnly: false,
                      icon: ' ',
                    }}
                    mode="basic"
                    name="profilePic"
                    accept="image/*"
                    maxFileSize={5242880}
                  />
                </>
              ) : (
                <>
                  <button
                    className="btn_outline w-50"
                    onClick={() => {
                      setProfilePicDialogVisible(false);
                      setSelectedPhoto(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-primary w-50"
                    onClick={() => onUpload()}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Dialog>

      <div className="top-header d-flex justify-content-between align-items-center sticky-top">
        <div className="main-nav-end d-flex align-items-center gap-3 w-100 justify-content-end">
          <span className="mb-0 d-none d-lg-block">Hi, {userData.name}</span>
          {verticleLineIcon}
          {/* <NotificationsBadge
            onTopbarNotification={props.onTopbarNotification}
            topbarNotificationMenuActive={props.topbarNotificationMenuActive}
          /> */}
          <div
            className="user-icon d-flex justify-content-center align-items-center"
            onClick={event => rightMenu.current.toggle(event)}
          >
            <Menu
              model={items}
              popup
              ref={rightMenu}
              style={{ width: 'auto' }}
            />

            {
              <a>
                {userData.profilePicture === null ||
                userData.profilePicture === '' ? (
                  <span className="initial pl-2">{getLabel}</span>
                ) : (
                  <img
                    className="img-shape"
                    alt="profile-picture"
                    src={userData.profilePicture}
                  />
                )}
                <span className="ms-1">{arrowDownIcon}</span>
              </a>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AppTopbar;
