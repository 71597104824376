import { memo, useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { useWalkthrough } from './useWalkthrough';

const OVERLAY_SHOW_DELAY = 200;

export const WalkthroughOverlay = memo(({ target, currentStep, view }) => {
  const [visible, setVisible] = useState(true);
  const [stepContent, setStepContent] = useState();
  const { skipWalkthrough, jumpToStep, nextStep, previousStep } =
    useWalkthrough();
  const overlayRef = useRef(null);
  const steps = useMemo(() => Object.keys(view), [view]);

  useEffect(() => {
    if (overlayRef.current && target.ref.current) {
      setTimeout(() => {
        overlayRef.current.show(null, target.ref.current);
        setStepContent(view[currentStep]);
      }, OVERLAY_SHOW_DELAY);
    }
  }, [currentStep, target, view]);

  useEffect(() => {
    if (target.ref.current) {
      const element = target.ref.current;
      const parentElement = target.parentRef?.current;
      element.classList.add('walkthrough-target-highlight');
      parentElement?.classList?.add('walkthrough-overlay-custom');

      return () => {
        if (element) {
          element.classList.remove('walkthrough-target-highlight');
          parentElement?.classList?.remove('walkthrough-overlay-custom');
        }
      };
    }
  }, [target, currentStep]);

  const handleClose = useCallback(() => {
    if (currentStep === steps[steps.length - 1]) {
      setVisible(false);
    }
  }, [currentStep, steps]);

  if (steps?.length === 0) {
    return null;
  }

  return (
    <>
      {visible && <div className="walkthrough-overlay" />}
      <OverlayPanel
        dismissable={false}
        appendTo={'self'}
        ref={overlayRef}
        className="walkthrough-panel"
      >
        <div className="popover-header p-3">
          <div className="d-flex justify-content-between align-items-start">
            <div className="popover-title">
              <h2 className="mb-1">{stepContent?.title}</h2>
            </div>
          </div>
          <div className="popover-body">
            <div className="my-3">
              <p>{stepContent?.description}</p>
            </div>
          </div>
          <div className="popover-footer border-0 d-flex gap-2 align-items-center justify-content-between flex-nowrap">
            <div className="d-flex gap-1 align-items-center">
              <Button
                label="Skip"
                className="bg-transparent border-0 walkthrough-skip-transaction me-3"
                onClick={() => {
                  skipWalkthrough();
                  handleClose();
                }}
              />
              {steps?.length > 1 &&
                steps.map(step => (
                  <button
                    key={step}
                    onClick={() => step !== currentStep && jumpToStep(step)}
                    className={
                      step === currentStep
                        ? 'walkthrough-active-btn-state'
                        : 'walkthrough-step-btn'
                    }
                  />
                ))}
            </div>
            <div className="flex align-center">
              {steps?.length > 1 && (
                <Button
                  disabled={currentStep === steps[0]}
                  label="Back"
                  onClick={() => previousStep(currentStep)}
                  className="bg-transparent text-black cursor-pointer border-0 skip-transaction me-3 rounded-2"
                />
              )}
              <Button
                label={
                  currentStep === steps[steps.length - 1] ? 'Close' : 'Next'
                }
                onClick={() => {
                  nextStep(currentStep);
                  handleClose();
                }}
                className="btn-primary border-0 rounded-2"
              />
            </div>
          </div>
        </div>
      </OverlayPanel>
    </>
  );
});
