import { createContext, memo, useCallback, useRef, useState } from 'react';

export const LoaderContext = createContext();

const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const timeoutIdRef = useRef(null);

  const setLoading = useCallback(status => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }
    if (status) {
      setLoadingPercent(0);
      timeoutIdRef.current = setTimeout(() => setIsLoading(status), 0);
    } else {
      setLoadingPercent(100);
      timeoutIdRef.current = setTimeout(() => {
        setIsLoading(status);
        setLoadingPercent(0);
      }, 250);
    }
  }, []);

  const value = {
    isLoading,
    loadingPercent,
    setLoading,
    setLoadingPercent,
  };

  return (
    <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>
  );
};

export default memo(LoaderProvider);
