import useLoader from 'hooks/useLoader';
import { useRef, useEffect } from 'react';

export const ProgressBar = () => {
  const { setLoadingPercent, loadingPercent } = useLoader();
  const initialDelay = 5;

  const timeoutIdRef = useRef(null);

  useEffect(() => {
    const increasePercentage = () => {
      setLoadingPercent(prevPercentage => {
        if (prevPercentage < 100) {
          const newPercentage = prevPercentage + 1;
          return newPercentage;
        }
        return prevPercentage;
      });
    };

    // increase delay exponentially as percentage increases and is above 70
    const getDelay = () => {
      const delayIncrease =
        loadingPercent < 70
          ? Math.floor(loadingPercent / 10) * 0.6 * initialDelay
          : Math.pow(loadingPercent - 70, 3);
      return initialDelay + delayIncrease;
    };

    timeoutIdRef.current = setTimeout(increasePercentage, getDelay());

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [loadingPercent, setLoadingPercent]);

  return (
    <div
      className="page-loading sticky-loader"
      style={{ width: `${loadingPercent}%` }}
    ></div>
  );
};
