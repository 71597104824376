// Format the date string that comes from the server
export const formatDateString = dateString =>
  new Date(dateString).toLocaleString('en-US', {
    dateStyle: 'long',
    timeStyle: 'short',
  });

export const getCurrencyString = str =>
  Number(str)?.toLocaleString('en-US', { style: 'currency', currency: 'USD' });

// Remove leading and trailing white spaces from all the string values in an object
export const trimObjectValues = obj =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: obj[key] && obj[key].trim ? obj[key].trim() : obj[key],
    }),
    {},
  );

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const mediumPasswordRegex =
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})';
export const strongPasswordRegex =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{10,})';

export const formatPhoneNumber = value => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.toString().length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
};

export const formatDate = date => {
  const value = new Date(date);

  return `${(value.getMonth() + 1).toString().padStart(2, '0')}-${value
    .getDate()
    .toString()
    .padStart(2, '0')}-${value.getFullYear()}`;
};
