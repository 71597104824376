import { memo, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTitle = () => {
  const location = useLocation();

  const getTitle = useCallback(currentPath => {
    if (
      currentPath === '/transactions' ||
      currentPath === '/transactions/' ||
      currentPath === '/'
    ) {
      return 'Home';
    }

    if (currentPath.includes('transactions')) {
      return 'Transaction';
    }

    if (currentPath.includes('closingmemos')) {
      return 'Closing Memos';
    }

    if (currentPath.includes('team')) {
      return 'Team';
    }

    if (currentPath.includes('support')) {
      return 'Support';
    }

    if (currentPath.includes('settings')) {
      return 'Settings';
    }
  }, []);

  useEffect(() => {
    const title = getTitle(location.pathname);
    document.title = title ? `${title} - WireSafe` : 'WireSafe';
  }, [getTitle, location.pathname]);

  return null;
};

export default memo(PageTitle);
