import { useContext } from 'react';
import { WalkthroughContext } from './WalkthroughProvider';

export const useWalkthrough = () => {
  const context = useContext(WalkthroughContext);

  if (!context) {
    throw new Error('useWalkthrough must be used within a WalkthroughProvider');
  }

  return context;
};
