import { useAuth0 } from '@auth0/auth0-react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MasterDataService from 'service/MasterDataService';

export const MasterDataContext = createContext();

export const MasterDataProvider = ({ children }) => {
  const [masterData, setMasterData] = useState({});
  const masterDataSvc = useMemo(() => new MasterDataService(), []);
  const { isAuthenticated } = useAuth0();

  const reloadMasterOrgs = useCallback(async () => {
    try {
      const masterOrgs = await masterDataSvc.getOrganizations();
      setMasterData(currentMasterData => ({
        ...currentMasterData,
        masterOrgs: masterOrgs.data,
      }));
    } catch (error) {
      console.error('Error reloading master organizations:', error);
    }
  }, [masterDataSvc]);

  const reloadMasterIssuers = useCallback(async () => {
    try {
      const masterIssuers = await masterDataSvc.getIssuers();
      setMasterData(currentMasterData => ({
        ...currentMasterData,
        masterIssuers: masterIssuers.data,
      }));
    } catch (error) {
      console.error('Error reloading master issuers:', error);
    }
  }, [masterDataSvc]);

  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        const masterData = await masterDataSvc.getAllMasterData();
        const {
          organizationMasterRoles,
          purposes,
          issuers,
          organizations,
          transactionStatuses,
          wireStatuses,
          states,
        } = masterData.data;
        setMasterData({
          organizationMasterRoles: organizationMasterRoles,
          masterPurposes: purposes,
          masterIssuers: issuers,
          masterOrgs: organizations,
          masterTxnStatuses: transactionStatuses,
          masterWireStatuses: wireStatuses,
          masterUsStates: states,
          reloadMasterOrgs,
          reloadMasterIssuers,
        });
      } catch (error) {
        console.error('Error fetching master data:', error);
      }
    };
    if (isAuthenticated === true) {
      fetchMasterData();
    }
  }, [isAuthenticated, masterDataSvc, reloadMasterIssuers, reloadMasterOrgs]);

  return (
    <MasterDataContext.Provider value={masterData}>
      {children}
    </MasterDataContext.Provider>
  );
};

export const useMasterData = () => {
  const masterData = useContext(MasterDataContext);
  return masterData;
};
