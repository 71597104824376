import * as Sentry from '@sentry/react';
import {
  useEffect,
  useLayoutEffect,
  useState,
  Suspense,
  lazy,
  memo,
} from 'react';
import { useToast } from 'hooks/useToast';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import App from 'App/App';
import { useUser } from 'hooks/useUser';
import AccountService from 'service/AccountService';
import constants from 'constants/constants';
import { Spinner } from './components/Spinner/Spinner';

const UsersIndex = lazy(() => import('components/Users/UsersIndex'));
const InvoicesIndex = lazy(() => import('components/Invoices/InvoicesIndex'));
const InvoicesView = lazy(() => import('components/Invoices/InvoicesView'));
const TransactionViewNew = lazy(() =>
  import('components/Transactions/TransactionView-new'),
);
const PasswordResetIndex = lazy(() =>
  import('components/PasswordReset/PasswordResetIndex'),
);
const ParticipantInvitation = lazy(() =>
  import('components/Invitations/ParticipantInvitation'),
);
const EmailVerificationIndex = lazy(() =>
  import('components/EmailVerification/EmailVerificationIndex'),
);
const WireInvitationIndex = lazy(() =>
  import('components/Invitations/WireInvitation'),
);
const DashboardIndex = lazy(() =>
  import('components/Dashboard/DashboardIndex'),
);
const InvitationsIndex = lazy(() =>
  import('components/Invitations/InvitationsIndex'),
);
const InstructionsIndex = lazy(() =>
  import('components/Instructions/InstructionsIndex'),
);
const Login = lazy(() => import('pages/Login'));
const Register = lazy(() => import('pages/Register'));
const TransactionsIndex = lazy(() =>
  import('components/Transactions/TransactionsIndex'),
);
const HistoryChangesIndex = lazy(() =>
  import('components/HistoryChanges/HistoryChangesIndex'),
);
const Team = lazy(() => import('components/Team/Team'));
const Settings = lazy(() => import('components/Profile/settings'));
const Support = lazy(() => import('components/Support/SupportIndex'));
const ClosingMemo = lazy(() => import('components/ClosingMemo/closingMemo'));
const TransactionView = lazy(() =>
  import('components/Transactions/TransactionView/TransactionView'),
);

const AppWrapper = () => {
  const location = useLocation();

  const [invitationParticipantId, setInvitationParticipantId] = useState(null);
  const [invitationWireId, setInvitationWireId] = useState(null);
  const [loginProgress, setLoginProgress] = useState(false);
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const { setUserData, setRole, role, userData, clear } = useUser();

  const toast = useToast();

  useEffect(() => {
    const accountService = new AccountService();
    if (isAuthenticated) {
      setLoginProgress(true);
      (async () => {
        const tokenResponse = await getAccessTokenSilently({
          detailedResponse: true,
          authorizationParams: {
            audience: `${process.env.REACT_APP_AUTH0_ISSUER}/api/v2/`,
            scope: 'read:current_user',
          },
        });
        accountService
          .loginWithauth0Token(tokenResponse)
          .then(({ data }) => {
            setUserData({
              name: data.name,
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone,
              email: data.email,
              title: data.title,
              organization: data.organization,
              orgRole: data.orgRole,
              isUnderwriter: data.isUnderwriter,
              profilePicture: data.profilePicture,
            });
            setRole(data.role);
            setLoginProgress(false);
          })
          .catch(error => {
            // Wrong password
            if (error.response.status === 400) {
              toast.current.show({
                severity: 'error',
                summary: error.response.data,
                life: 8000,
              });
            }
            // Unverified email
            if (error.response.status === 401) {
              toast.current.show({
                severity: 'error',
                summary: error.response.data,
                detail: 'An email was sent to you to verify your account',
                life: 8000,
              });
            }
            // Account inactive or blocked
            if (error.response.status === 403) {
              toast.current.show({
                severity: 'error',
                summary: "Can't login",
                detail: error.response.data,
                life: 8000,
              });
            }
            if (error.response.status === 404) {
              toast.current.show({
                severity: 'error',
                summary: 'User not found',
                life: 8000,
              });
            }

            setLoginProgress(false);
            clear();
          })
          .finally();
      })();
    }
  }, [isAuthenticated]);

  // State update must be synchronous
  // Otherwise, a previous invitationParticipantId value may be used
  useLayoutEffect(() => {
    const invitationIdFromParticipantURL = location.pathname.startsWith(
      '/participant-invitation/',
    )
      ? location.pathname.substring(24)
      : null;
    const invitationIdFromWireURL = location.pathname.startsWith(
      '/wire-invitation/',
    )
      ? location.pathname.substring(17)
      : null;

    if (invitationIdFromParticipantURL) {
      setInvitationParticipantId(invitationIdFromParticipantURL);
    }

    if (invitationIdFromWireURL) {
      setInvitationWireId(invitationIdFromWireURL);
    }
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <>
      {(loginProgress || isLoading) && <Spinner />}
      <Suspense fallback={<Spinner />}>
        <SentryRoutes>
          {isAuthenticated && !loginProgress && role ? (
            <Route path="/" element={<App />}>
              {/* Routes for all users */}
              <Route path="transactions" element={<TransactionsIndex />} />
              <Route path="closingMemos" element={<ClosingMemo />} />
              <Route path="team" element={<Team />} />
              <Route path="settings" element={<Settings />} />
              <Route path="support" element={<Support />} />
              <Route
                path="transactions/:transactionId"
                element={<TransactionView />}
              />
              <Route
                path="new-transaction-view"
                element={<TransactionViewNew />}
              />
              <Route
                path="participant-invitation/:participantId"
                element={<ParticipantInvitation />}
              />
              <Route
                path="wire-invitation/:wireId"
                element={<WireInvitationIndex />}
              />
              {/* Routes for Super Admin only */}
              {/* {role === constants.ROLES.SUPER_ADMIN && (
              <>
                <Route path="/" element={<DashboardIndex />} />
                <Route path="organizations" element={<OrganizationsIndex />} />
                <Route path="users" element={<UsersIndex />} />
                <Route path="wires" element={<WiresIndex />} />
                <Route path="invoices" element={<InvoicesIndex />} />
                <Route path="invoices/:invoiceId" element={<InvoicesView />} />
                <Route
                  path="history-changes"
                  element={<HistoryChangesIndex />}
                />
              </>
            )} */}
              {/* Routes for Organization Admin only */}
              {role === constants.ROLES.ORGANIZATION_ADMIN && (
                <>
                  <Route path="/" element={<TransactionsIndex />} />
                  <Route path="users" element={<UsersIndex />} />
                  <Route path="invoices" element={<InvoicesIndex />} />
                  <Route
                    path="invoices/:invoiceId"
                    element={<InvoicesView />}
                  />
                  <Route path="invitations" element={<InvitationsIndex />} />
                  <Route
                    path="history-changes"
                    element={<HistoryChangesIndex />}
                  />
                  <Route path="instructions" element={<InstructionsIndex />} />
                </>
              )}
              {/* Routes for User only */}
              {role === constants.ROLES.USER && (
                <>
                  {userData.organization && (
                    <Route path="/" element={<TransactionsIndex />} />
                  )}
                  {!userData.organization && (
                    <Route path="/" element={<DashboardIndex />} />
                  )}
                  <Route path="invoices" element={<InvoicesIndex />} />
                  <Route
                    path="invoices/:invoiceId"
                    element={<InvoicesView />}
                  />
                  <Route path="invitations" element={<InvitationsIndex />} />
                  <Route path="instructions" element={<InstructionsIndex />} />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          ) : (
            <>
              {!isAuthenticated && !isLoading ? (
                <>
                  <Route path="/login" element={<Login />} />
                  <Route path="/logincallback" element={<Spinner />} />
                  <Route path="/register" element={<Register />} />
                  <Route
                    path="/participant-invitation/:participantId"
                    element={
                      <Navigate
                        to={`/login?redirectToInvitationParticipantId=${invitationParticipantId}`}
                      />
                    }
                  />
                  <Route
                    path="/wire-invitation/:wireId"
                    element={
                      <Navigate
                        to={`/login?redirectToInvitationWireId=${invitationWireId}`}
                      />
                    }
                  />
                  <Route
                    path="/account/:userId/email-verification"
                    element={<EmailVerificationIndex />}
                  />
                  <Route
                    path="/password-reset"
                    element={<PasswordResetIndex />}
                  />
                  <Route path="*" element={<Login />} />
                </>
              ) : (
                <> </>
              )}
            </>
          )}
        </SentryRoutes>
      </Suspense>
    </>
  );
};

export default memo(AppWrapper);
