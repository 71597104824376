import { memo, useCallback } from 'react';

const ErrorView = ({ type, name }) => {
  const currentPath = window.location.pathname;
  const isPage = type === 'page';

  const isHomePage =
    currentPath === '/transactions' ||
    currentPath === '/transactions/' ||
    currentPath === '/';

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  const handleRedirect = useCallback(() => {
    window.location.href = '/';
  }, []);

  return (
    <div
      className={`flex flex-column align-items-center justify-content-center ${
        isPage ? 'min-h-screen' : ''
      } p-2`}
    >
      <div className="text-center">
        <p className="text-500 mb-2">Internal error</p>
        <h1 className="text-900 font-bold text-4xl mb-3">
          Something Went Wrong
        </h1>
        <p className="text-700 mb-5">
          {`We encountered an unknown error ${
            name ? 'when loading ' + name : ''
          }`}{' '}
        </p>
        {!isHomePage && type === 'page' ? (
          <button
            className="p-button p-component p-button-outlined p-2"
            onClick={handleRedirect}
          >
            <span className="p-button-icon p-button-icon-left pi pi-arrow-left" />
            <span className="p-button-label font-semibold">
              Go back to all Transactions
            </span>
          </button>
        ) : (
          <button
            className="p-button p-component p-button-outlined p-2"
            onClick={handleReload}
          >
            <span className="p-button-icon p-button-icon-left pi pi-refresh" />
            <span className="p-button-label font-semibold">Reload</span>
          </button>
        )}
      </div>
      <div
        className={`mt-5 flex ${
          isPage ? 'w-2' : 'flex-column w-8'
        } align-items-center  border-top-1 border-bottom-1 border-200 py-4`}
      >
        <i className="pi pi-question-circle mr-3 text-2xl p-2 border-1 border-round border-300" />
        <div className={`${isPage ? '' : 'text-center mt-2'}`}>
          <p className="font-medium text-900 mb-0">Need Help?</p>
          <p className="text-600 mt-1">
            If this issue persists, please contact our support team at{' '}
            <a
              href="mailto:support@wiresafe.com"
              className="support-email font-semibold underline"
            >
              support@wiresafe.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(ErrorView);
