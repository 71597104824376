import constants from 'constants/constants';
import { UserContext } from 'Context/UserContext';
import { useMemo, useState } from 'react';
import UserStorage from 'storage/userStorage';
import { useAuth0 } from '@auth0/auth0-react';

const UserProvider = ({ children, awsRum }) => {
  const userStorage = useMemo(() => new UserStorage(), []);

  const [userData, setUserData] = useState(() => userStorage.getUserData());
  const [role, setRole] = useState(() => userStorage.getRole());
  const { logout } = useAuth0();

  const value = useMemo(
    () => ({
      setUserData(data) {
        setUserData(userData => {
          const newUserData = { ...userData, ...data };
          userStorage.setUserData(newUserData);
          return newUserData;
        });
      },
      setRole(role) {
        const isInvalidRole = !Object.values(constants.ROLES).includes(role);

        if (isInvalidRole) {
          throw new Error(`Invalid role: ${role}`);
        }

        userStorage.setRole(role);
        setRole(role);
      },
      userData,
      role,
      isUnderwriter: userData ? userData.isUnderwriter : false,
      clear() {
        userStorage.clear();
        localStorage.clear();

        logout({
          logoutParams: { returnTo: window.location.origin + '/login' },
        }).finally(() => {
          setUserData(null);
          setRole(null);
        });
      },
      awsRum,
    }),
    [awsRum, logout, role, userData, userStorage],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
