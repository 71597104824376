import { useToast } from 'hooks/useToast';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AccountService from 'service/AccountService';
import { mediumPasswordRegex, strongPasswordRegex } from 'utilities/format';

function ChangePassword({ onPasswordChanged }) {
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      password: '',
      newPassword: '',
    },
    reValidateMode: 'onChange',
  });
  const newPassword = watch('newPassword');

  const accountService = new AccountService();

  const toast = useToast();

  const onSubmit = ({ password, newPassword }) => {
    setIsLoading(true);

    accountService
      .changePassword(password, newPassword)
      .then(() => {
        toast.current.show({
          severity: 'success',
          summary: 'Password changed successfully',
          life: 8000,
        });

        onPasswordChanged();
      })
      .catch(error => {
        if (error.response.status === 400) {
          toast.current.show({
            severity: 'error',
            summary: error.response.data,
            life: 8000,
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  const passwordFooter = (
    <>
      <Divider />
      <p className="mt-2">Requirements</p>
      <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>At least one special character</li>
        <li>Minimum 10 characters</li>
      </ul>
    </>
  );

  return (
    <div className="card">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="p-fluid formgrid grid row-gap-3"
      >
        <div className="field col-12">
          <label htmlFor="password">Current password</label>
          <Controller
            name="password"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required field',
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <Password
                  id={field.name}
                  toggleMask
                  feedback={false}
                  inputClassName={
                    fieldState.error ? 'input-error' : 'input-correct'
                  }
                  placeholder="Current password"
                  value={field.value}
                  onChange={e => field.onChange(e.target.value.trim())}
                />
                <br />
                {fieldState.error && (
                  <span className="p-error absolute bottom-0">
                    {fieldState.error.message}
                  </span>
                )}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="newPassword">New password</label>
          <Controller
            name="newPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required field',
              },
              minLength: {
                value: 10,
                message: 'Minimum of 8 characters',
              },
              validate: {
                containsLowerCase: v =>
                  /[a-z]/.test(v) || 'Use at least one lower case',
                containsUppercase: v =>
                  /[A-Z]/.test(v) || 'Use at least one uppercase',
                containsNumeric: v =>
                  /\d/.test(v) || 'Use at least one numeric',
                containsSpecialCharacter: v =>
                  /\W|_/.test(v) || 'Use at least one special character',
              },
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <Password
                  id={field.name}
                  toggleMask
                  inputClassName={
                    fieldState.error ? 'input-error' : 'input-correct'
                  }
                  placeholder="New password"
                  value={field.value}
                  onChange={e => field.onChange(e.target.value.trim())}
                  footer={passwordFooter}
                  mediumRegex={mediumPasswordRegex}
                  strongRegex={strongPasswordRegex}
                />
                <br />
                {fieldState.error && (
                  <span className="p-error absolute bottom-0">
                    {fieldState.error.message}
                  </span>
                )}
              </div>
            )}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="confirmNewPassword">Confirm new password</label>
          <Controller
            name="confirmNewPassword"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required field',
              },
              validate: value =>
                value === newPassword || 'Passwords do not match',
            }}
            render={({ field, fieldState }) => (
              <div className="relative">
                <Password
                  id={field.name}
                  inputClassName={
                    fieldState.error ? 'input-error' : 'input-correct'
                  }
                  toggleMask
                  feedback={false}
                  placeholder="Confirm new password"
                  value={field.value}
                  onChange={e => field.onChange(e.target.value.trim())}
                />
                <br />
                {fieldState.error && (
                  <span className="p-error absolute bottom-0">
                    {fieldState.error.message}
                  </span>
                )}
              </div>
            )}
          />
        </div>

        <div className="spacer" />

        <div className="field col-12 md:col-12 registerBottom">
          <div className="field col-12 md:col-5 col-offset-6">
            <Button loading={isLoading} label="Submit" id="register-button" />
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
