import Constants from '../constants/constants';
export default class UserStorage {
  #USER_DATA_KEY = 'user_data';
  #ROLE_KEY = 'role';
  #WALK_THROUGH = 'wiresafe_walkthrough';

  setUserData(userData) {
    localStorage.setItem(this.#USER_DATA_KEY, JSON.stringify(userData));
  }

  getUserData() {
    return JSON.parse(localStorage.getItem(this.#USER_DATA_KEY));
  }

  setRole(role) {
    localStorage.setItem(this.#ROLE_KEY, JSON.stringify(role));
  }

  getRole() {
    return JSON.parse(localStorage.getItem(this.#ROLE_KEY));
  }

  setWalkthrough(viewName) {
    localStorage.setItem(
      `${this.#WALK_THROUGH}-${viewName}`,
      JSON.stringify(1),
    );
  }

  getWalkthrough(viewName) {
    return JSON.parse(
      localStorage.getItem(`${this.#WALK_THROUGH}-${viewName}`),
    );
  }

  clearWalkthrough() {
    localStorage.removeItem(
      `${this.#WALK_THROUGH}-${
        Constants.WALK_THROUGH_VIEWNAME.TRANSACTIONS_VIEW
      }`,
    );
    localStorage.removeItem(
      `${this.#WALK_THROUGH}-${
        Constants.WALK_THROUGH_VIEWNAME.TRANSACTION_VIEW
      }`,
    );
  }

  clear() {
    localStorage.removeItem(this.#USER_DATA_KEY);
    localStorage.removeItem(this.#ROLE_KEY);
  }
}
