import {
  memo,
  useMemo,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { WalkthroughOverlay } from './WalkthroughOverlay';
import UserStorage from 'storage/userStorage';

export const WalkthroughContext = createContext();

export const WalkthroughProvider = memo(({ children }) => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState();
  const userStorage = useMemo(() => new UserStorage(), []);
  const [viewName, setViewName] = useState();
  const [view, setView] = useState();
  const [skip, setSkip] = useState(false);
  const [targetRegistry, setTargetRegistry] = useState({});

  const registerTarget = useCallback((key, ref, parentRef) => {
    setTargetRegistry(prev => ({
      ...prev,
      [key]: { ref, parentRef },
    }));
  }, []);

  const startWalkthrough = useCallback(
    (viewName, view) => {
      const times = userStorage.getWalkthrough(viewName);
      if (times === 1) {
        return;
      }

      const registeredSteps = Object.keys(targetRegistry ?? {});
      if (registeredSteps.length === 0) {
        return;
      }

      const stepsToRender = {};

      for (let stepKey in view) {
        if (registeredSteps.includes(stepKey)) {
          stepsToRender[stepKey] = view[stepKey];
        }
      }

      const steps = Object.keys(stepsToRender ?? {});
      if (steps.length === 0) {
        return;
      }

      setCurrentStep(steps[0]);
      setView(stepsToRender);
      setViewName(viewName);
    },
    [userStorage, targetRegistry],
  );

  const jumpToStep = useCallback(step => {
    setCurrentStep(step);
  }, []);

  const resetWalkthrough = useCallback(() => {
    setSkip(true);
    setCurrentStep();
    setView();
    setTargetRegistry({});
  }, []);

  const skipWalkthrough = useCallback(() => {
    resetWalkthrough();
    userStorage.setWalkthrough(viewName);
  }, [resetWalkthrough, userStorage, viewName]);

  const getCurrentStep = useCallback(
    currentKey => {
      const keys = Object.keys(view);
      const index = keys.indexOf(currentKey);

      if (index === keys.length - 1) {
        userStorage.setWalkthrough(viewName);
      }

      return [index, keys];
    },
    [view, viewName, userStorage],
  );

  const nextStep = useCallback(
    currentKey => {
      const [currentIndex, keys] = getCurrentStep(currentKey);

      if (currentIndex < keys.length - 1) {
        setCurrentStep(keys[currentIndex + 1]);
      } else {
        setCurrentStep();
      }
    },
    [getCurrentStep],
  );

  const previousStep = useCallback(
    currentKey => {
      const [currentIndex, keys] = getCurrentStep(currentKey);

      if (currentIndex > 0) {
        setCurrentStep(keys[currentIndex - 1]);
      } else {
        setCurrentStep();
      }
    },
    [getCurrentStep],
  );

  useEffect(() => {
    return () => {
      setView();
    };
  }, [location.pathname]);

  const contextValue = {
    registerTarget,
    startWalkthrough,
    skipWalkthrough,
    jumpToStep,
    nextStep,
    previousStep,
  };

  return (
    <WalkthroughContext.Provider value={contextValue}>
      {children}
      {!skip && view && currentStep && targetRegistry[currentStep] && (
        <WalkthroughOverlay
          target={targetRegistry[currentStep]}
          currentStep={currentStep}
          view={view}
        />
      )}
    </WalkthroughContext.Provider>
  );
});
