import { Component, memo } from 'react';
import ErrorView from './ErrorView';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorView type={this.props.type} name={this.props.name} />;
    }

    return this.props.children;
  }
}

export default memo(ErrorBoundary);
