import axios from 'providers/ConfigureAxios';
import { getUrl } from 'service/config/serviceUrls';

export default class MasterDataService {
  getMasterPurpose() {
    return axios({
      method: 'get',
      url: `${getUrl()}/masterdata/purposes`,
    });
  }

  getMasterOrganizationRoles() {
    return axios({
      method: 'get',
      url: `${getUrl()}/masterdata/organizationMasterRoles`,
    });
  }

  getIssuers() {
    return axios({
      method: 'get',
      url: `${getUrl()}/masterdata/issuers?force=true`,
    });
  }

  getOrganizations() {
    return axios({
      method: 'get',
      url: `${getUrl()}/masterdata/organizations`,
    });
  }

  getTxnStatuses() {
    return axios({
      method: 'get',
      url: `${getUrl()}/masterdata/txnstatuses`,
    });
  }

  getAllMasterData() {
    return axios({
      method: 'get',
      url: `${getUrl()}/masterdata/all`,
    });
  }
}
