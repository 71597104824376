import axios from 'providers/ConfigureAxios';
import { getUrl } from 'service/config/serviceUrls';

export default class AccountService {
  register(body, inviteEmail) {
    if (inviteEmail) {
      return axios({
        method: 'post',
        url: `${getUrl()}/account/activate`,
        data: body,
      });
    }
    return axios({
      method: 'post',
      url: `${getUrl()}/account/register`,
      data: body,
    });
  }

  login(body) {
    return axios({
      method: 'post',
      url: `${getUrl()}/account/login`,
      data: body,
    });
  }

  loginWithauth0Token(token) {
    return axios({
      method: 'post',
      url: `${getUrl()}/api/validatetoken/validatetoken`,
      data: { idToken: token.id_token, accessToken: token.access_token },
    });
  }

  verifyEmail(userId) {
    return axios({
      method: 'get',
      url: `${getUrl()}/account/${userId}/verify-email`,
    });
  }

  sendPasswordResetLink() {
    return axios({
      method: 'post',
      url: `${getUrl()}/account/password-reset-link`,
    });
  }

  resetPassword(password) {
    return axios({
      method: 'post',
      url: `${getUrl()}/account/reset-password`,
      data: {
        password,
      },
    });
  }

  changePassword(password, newPassword) {
    return axios({
      method: 'post',
      url: `${getUrl()}/account/change-password`,
      data: {
        password,
        newPassword,
      },
    });
  }
}
