const CLAIM_TYPE_NAMESPACE_2008 =
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/';

const CLAIM_TYPE_NAMESPACE_2005 =
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/';

const WIRESAFE = 'WireSafe';

const ROLES = {
  SUPER_ADMIN: 'Super Admin',
  ORGANIZATION_ADMIN: 'Organization Admin',
  USER: 'User',
};

const USER_STATUS = {
  PENDING: 'Pending',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  BLOCKED: 'Blocked',
};

const PARTICIPANT_STATUS = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  CANCELED: 'Canceled',
  INVITED: 'Invited',
};

const TRANSACTION_ROLES_OTHER_PARTICIPANTS = {
  ADVISOR: 'Advisor',
  BANK: 'Bank',
  BOND_COUNSEL: 'Bond Counsel',
  COUNTY: 'County',
  DISCLOSURE_COUNSEL: 'Disclosure Counsel',
  ESCROW_AGENT: 'Escrow Agent',
  ISSUER: 'Issuer',
  PLACEMENT_AGENT: 'Placement Agent',
  TRUSTEE: 'Trustee',
  UNDERWRITER: 'Underwriter',
  UNDERWRITERS_COUNSEL: "Underwriter's Counsel",
};

const ORGANISATION_ROLES = {
  FINANCIAL_ADVISOR: 1,
  COUNTY: 2,
  BOND_AND_DISCLOSURE: 3,
  RATING_AGENCY: 4,
  COI_CUSTODIAN: 5,
  VERIFICATION_AGENT: 6,
  OFFICIAL_STATEMENT_PRINTING: 7,
  ESCROW_AGENT: 8,
  TRUSTEE: 9,
  BOND_INSURER: 10,
  UNDERWRITER: 11,
  PAYING_AGENT: 12,
  BOND_COUNSEL: 13,
};

const TRANSACTION_ROLES = {
  TRANSACTION_MANAGER: 'Transaction Manager',
  WIRE_SENDER: 'Wire Sender',
  WIRE_RECEIVER: 'Wire Receiver',
  ADVISOR: 'Advisor',
  BANK: 'Bank',
  BOND_COUNSEL: 'Bond Counsel',
  COUNTY: 'County',
  DISCLOSURE_COUNSEL: 'Disclosure Counsel',
  ESCROW_AGENT: 'Escrow Agent',
  ISSUER: 'Issuer',
  PLACEMENT_AGENT: 'Placement Agent',
  TRUSTEE: 'Trustee',
  UNDERWRITER: 'Underwriter',
  UNDERWRITERS_COUNSEL: "Underwriter's Counsel",
};

const TRANSACTION_TYPES = {
  COMPETITIVE: 'Competitive',
  PRIVATE_PLACEMENT: 'Private Placement',
  NEGOTIATED: 'Negotiated',
};

const TRANSACTION_STATUS = {
  CREATED: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
};

const WIRE_STATUS = {
  DRAFT: 1,
  READY_TO_SEND: 2,
  PENDING_VERIFICATION: 3,
  DECLINED: 4,
  VERIFIED: 5,
};

const NOTIFICATION_TYPES = {
  WIRE_INVITATION: 'Wire Receiver Invitation',
  PARTICIPANT_INVITATION: 'Participant Invitation',
  WIRE_ACCEPTED: 'Wire Accepted',
  WIRE_REJECTED: 'Wire Rejected',
};

const SORT_ORDER = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
};

const SAVE_DRAFT_DIALOG = {
  TITLE: 'Are you sure you want to close?',
  DESCRIPTION: 'Do you want to save your progress as draft',
  CLOSE: 'Close Without Saving',
  SAVE_AS_DRAFT: 'Save as Draft',
};

const ACTION_PERMISSION = {
  CREATE_TRANSACTION: 'CREATE_TRANSACTION',
  DELETE_TRANSACTION: 'DELETE_TRANSACTION',
  EDIT_TRANSACTION: 'EDIT_TRANSACTION',
  ADD_WIRE: 'ADD_WIRE',
  DELETE_WIRE: 'DELETE_WIRE',
  EDIT_WIRE: 'EDIT_WIRE',
  SEND_WIRE: 'SEND_WIRE',
  ADD_TRANSACTION_TEAM_MEMBER: 'ADD_TRANSACTION_TEAM_MEMBER',
  SELECT_TRANSACTION_TYPE_FILTER: 'SELECT_TRANSACTION_TYPE_FILTER',
  SELECT_CREATED_TRANSACTION_STATUS: 'SELECT_CREATED_TRANSACTION_STATUS',
};

const VIEW_PERMISSION = {
  SPECIFIC_WIRE_STATUS_INFO: 'SPECIFIC_WIRE_STATUS_INFO',
};

const WIRE_FIELDS = {
  FBO: 'FBO',
  ABA: 'ABA',
  AMOUNT: 'Amount',
  ROLE: 'Role',
  WIRE_PURPOSES: 'WirePurposes',
  ACCOUNT_NUMBER: 'AccountNumber',
  RECEIVER: 'Receiver',
  ASSOCIATED_SERVICES: 'AssociatedServices',
  CONTINGENCY_AMOUNT: 'ContingencyAmount',
};

const PLATFORM_SECURITY_FEE = `Platform Security Fee, ${WIRESAFE}`;

const REJECTED_TEXT_CHANGE_COLOR = 'text-red-600';
const FINAL_TEXT_CHANGE_COLOR = 'text-green-600';

const TOAST_FADE_TIME = 8000;
const MASKED_STRING = 'XXXXXXXXXX';

export const WALK_THROUGH_VIEWNAME = {
  TRANSACTIONS_VIEW: 'TRANSACTIONS_VIEW',
  TRANSACTION_VIEW: 'TRANSACTION_VIEW',
};

const WALK_THROUGH_STEPS = {
  TRANSACTIONS_VIEW: {
    CREATE_TRANSACTION: {
      title: 'Add Transaction',
      description:
        'The create transaction action enables you to securely initiate new transactions.',
    },
  },

  TRANSACTION_VIEW: {
    ADD_WIRE: {
      title: 'Add Wire',
      description:
        'Click here to securely add Bank Details, Wire Details & Use of Funds of all the recipients. You can create multiple wires.',
    },
    ADD_BANK_DETAILS: {
      title: 'Add Bank Details',
      description:
        'Securely enter all the Bank details of the wire recipient. These details will remain confidential, visible only to you and the recipient.',
    },
    ADD_WIRE_DETAILS: {
      title: 'Add Wire Details',
      description:
        'Securely enter all the Wire details of the wire recipient. These details will be visible to all the transaction participants.',
    },
    ADD_COST_OF_ISSUANCE: {
      title: 'Add Cost of Issuance',
      description:
        'It includes all expenses related to issuing a transaction, such as fees, administrative costs, Platform Fee & contingency amounts.',
    },
    SEND_WIRE_INVITE: {
      title: 'Send Wire Invites',
      description:
        'Click Send Wire Invite to review and confirm the secure wire summary as the final step before sending to participants.',
    },
    ADD_TRANSACTION_TEAM: {
      title: 'Add Transaction Team',
      description:
        'Add team members from your organization to review and oversee the transaction to ensure transparency and accuracy before sending wire invites.',
    },
  },

  WIRE_RECEIVER: {
    TRANSACTIONS_VIEW: {
      OPEN_TRANSACTION: {
        title: 'Open Transaction',
        description:
          'Click anywhere on the transaction to open a detailed transaction page',
      },
    },

    TRANSACTION_VIEW: {
      ACTION_NEEDED: {
        title: 'Action Needed',
        description:
          'Review the transaction details and click here to take your pending action.',
      },
      REJECT_APPROVE_WIRE: {
        title: 'Reject or Approve Wire Details',
        description:
          'Thoroughly review your bank and wire details, then take action by either accepting or rejecting the wire.',
      },
      REJECTION_REMARKS: {
        title: 'If Rejected, Provide Remarks',
        description:
          'Give rejection remarks to the transaction manager by selecting multiple options from dropdown and click submit.',
      },
      APPROVE: {
        title: 'On Approval',
        description: `You're all set if your wire status is marked as "Verified" after approving. You can review other wire details or simply wait for further updates from WireSafe.`,
      },
    },
  },
};

const constants = {
  CLAIM_TYPE_NAMESPACE_2008,
  CLAIM_TYPE_NAMESPACE_2005,
  ROLES,
  USER_STATUS,
  WIRE_STATUS,
  PARTICIPANT_STATUS,
  TRANSACTION_ROLES_OTHER_PARTICIPANTS,
  TRANSACTION_ROLES,
  TRANSACTION_TYPES,
  TRANSACTION_STATUS,
  NOTIFICATION_TYPES,
  SORT_ORDER,
  ORGANISATION_ROLES,
  SAVE_DRAFT_DIALOG,
  ACTION_PERMISSION,
  VIEW_PERMISSION,
  WIRE_FIELDS,
  PLATFORM_SECURITY_FEE,
  WIRESAFE,
  REJECTED_TEXT_CHANGE_COLOR,
  FINAL_TEXT_CHANGE_COLOR,
  TOAST_FADE_TIME,
  MASKED_STRING,
  WALK_THROUGH_STEPS,
  WALK_THROUGH_VIEWNAME,
};

export default constants;
