import axios from 'providers/ConfigureAxios';
import { getUrl } from 'service/config/serviceUrls';

export default class UserService {
  getUsers() {
    return axios({
      method: 'get',
      url: `${getUrl()}/user/`,
    });
  }

  getTeamMembers() {
    return axios({
      method: 'get',
      url: `${getUrl()}/user/organization`,
    });
  }

  inActivateUser(userId) {
    return axios({
      method: 'put',
      url: `${getUrl()}/user/inactivate/${userId}`,
    });
  }

  updateUserRole(userId, isAdmin, email) {
    return axios({
      method: 'put',
      url: `${getUrl()}/user/update-user?userId=${userId}&isAdmin=${isAdmin}&email=${email}`,
    });
  }

  inviteUser(email, isAdmin) {
    return axios({
      method: 'post',
      url: `${getUrl()}/user/invite?isAdmin=${isAdmin}&email=${email}`,
    });
  }

  search(email) {
    return axios({
      method: 'get',
      url: `${getUrl()}/user/search/${email}`,
    });
  }

  isVerified(userId) {
    return axios({
      method: 'get',
      url: `${getUrl()}/user/${userId}/is-verified`,
    });
  }

  editUser(editUser) {
    return axios({
      method: 'put',
      url: `${getUrl()}/user`,

      data: editUser,
    });
  }

  editProfile(editUserProfile) {
    return axios({
      method: 'put',
      url: `${getUrl()}/user/profile`,

      data: editUserProfile,
    });
  }

  updateProfilePhoto(file) {
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
    }
    return axios({
      method: 'put',
      url: `${getUrl()}/user/profile-photo`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
  }
}
