import { memo, useMemo, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { ToastContext } from 'Context/ToastContext';

const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);
  const value = useMemo(() => toastRef, []);

  return (
    <>
      <ToastContext.Provider value={value}>
        <Toast ref={toastRef} position="bottom-center" life={5000} />
        {children}
      </ToastContext.Provider>
    </>
  );
};

export default memo(ToastProvider);
