import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { Outlet, useLocation } from 'react-router-dom';

import AppTopbar from './Topbar/AppTopbar';
import AppSearch from './AppSearch';
import { useUser } from 'hooks/useUser';

import { Tooltip } from 'primereact/tooltip';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'styles/App/App.scss';
import 'styles/customStyle.css';
import 'styles/register.scss';
import constants from 'constants/constants';
import dashboardIcon from 'components/Icons/Dashboard';
import organizationIcon from 'components/Icons/Organization';
import usersIcon from 'components/Icons/Users';
import transactionIcon from 'components/Icons/Transaction';
import invoiceIcon from 'components/Icons/Invoice';
import mailIcon from 'components/Icons/Mail';
import useLoader from 'hooks/useLoader';
import { ProgressBar } from 'components/Transactions/TransactionView/ProgressBar';
import { LeftSidebar } from './SideBar/LeftSidebar';

const App = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [colorScheme] = useState('light');
  const [menuTheme] = useState('layout-sidebar-custom');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(true);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] =
    useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle] = useState('outlined');
  const copyTooltipRef = useRef();
  const location = useLocation();
  const { role, userData } = useUser();
  const [toggleLeftBar, setToggleLeftBar] = useState(true);

  let menuClick = false;
  let searchClick = false;
  let userMenuClick = false;
  let configClick = false;
  let notificationMenuClick = false;

  const createBreadcrumb = (role, userHasOrganization) => {
    switch (role) {
      case constants.ROLES.SUPER_ADMIN:
        return [
          { path: '/', parent: '', label: 'Dashboard' },
          {
            path: '/organizations',
            parent: '',
            label: 'Organizations / Companies',
          },
          { path: '/transactions', parent: '', label: '' },
          { path: '/users', parent: '', label: 'Users' },
          { path: '/wires', parent: '', label: 'Wires' },
          { path: '/invoices', parent: '', label: 'Invoices' },
          { path: '/invitations', parent: '', label: 'Invitations' },
          { path: '/history-changes', parent: '', label: 'History changes' },
        ];
      case constants.ROLES.ORGANIZATION_ADMIN:
        return [
          { path: '/', parent: '', label: 'Transactions' },
          { path: '/transactions', parent: '', label: '' },
          { path: '/users', parent: '', label: 'Users' },
          { path: '/invoices', parent: '', label: 'Invoices' },
          { path: '/invitations', parent: '', label: 'Invitations' },
          { path: '/history-changes', parent: '', label: 'History changes' },
          { path: '/instructions', parent: '', label: 'Instructions' },
        ];
      case constants.ROLES.USER:
        if (userHasOrganization) {
          return [
            { path: '/', parent: '', label: 'Transactions' },
            { path: '/transactions', parent: '', label: '' },
            { path: '/invoices', parent: '', label: 'Invoices' },
            { path: '/invitations', parent: '', label: 'Invitations' },
            { path: '/instructions', parent: '', label: 'Instructions' },
          ];
        } else {
          return [
            { path: '/', parent: '', label: 'Dashboard' },
            { path: '/transactions', parent: '', label: '' },
            { path: '/invoices', parent: '', label: 'Invoices' },
            { path: '/invitations', parent: '', label: 'Invitations' },
            { path: '/instructions', parent: '', label: 'Instructions' },
          ];
        }
      default:
        throw new Error(`Invalid role: ${role}`);
    }
  };

  const breadcrumb = createBreadcrumb(role, userData?.organization !== null);

  const createMenu = (role, userHasOrganization) => {
    const separator = {
      separator: true,
      style: {
        display: 'block',
        width: '1px',
        height: '1.25rem',
        borderLeft: '1px solid rgba(246, 247, 252, 0.2)',
        alignSelf: 'center',
        margin: '0',
      },
    };
    const dashboard = {
      label: 'Dashboard',
      iconSVG: dashboardIcon,
      to: '/',
    };
    const organizations = {
      label: 'Organizations / Companies',
      iconSVG: organizationIcon,
      to: '/organizations',
    };
    const users = {
      label: 'Users',
      iconSVG: usersIcon,
      to: '/users',
    };
    const transactions = {
      label: 'Transactions',
      iconSVG: transactionIcon,
      to: '/transactions',
    };
    const wires = {
      label: 'Wires',
      icon: 'pi pi-fw pi-calendar',
      to: '/wires',
    };
    const invoices = {
      label: 'Invoices',
      iconSVG: invoiceIcon,
      to: '/invoices',
    };
    const invitations = {
      label: 'Invitations',
      iconSVG: mailIcon,
      to: '/invitations',
    };
    const audit = {
      label: 'History changes',
      icon: 'pi pi-fw pi-book',
      to: '/history-changes',
    };
    const instructions = {
      label: 'Instructions',
      icon: 'pi pi-fw pi-file-pdf',
      to: '/instructions',
    };
    switch (role) {
      case constants.ROLES.SUPER_ADMIN:
        return [
          dashboard,
          separator,
          organizations,
          separator,
          transactions,
          separator,
          users,
          separator,
          wires,
          separator,
          invoices,
          separator,
          audit,
        ];
      case constants.ROLES.ORGANIZATION_ADMIN:
        return [
          transactions,
          separator,
          users,
          separator,
          invoices,
          separator,
          invitations,
          separator,
          audit,
          separator,
          instructions,
        ];
      case constants.ROLES.USER:
        if (userHasOrganization) {
          return [
            transactions,
            separator,
            invitations,
            separator,
            invoices,
            separator,
            instructions,
          ];
        } else {
          return [
            dashboard,
            separator,
            transactions,
            separator,
            invitations,
            separator,
            invoices,
            separator,
            instructions,
          ];
        }
      default:
        throw new Error(`Invalid role: ${role}`);
    }
  };

  const menu = createMenu(role, userData.organization !== null);

  const meta = breadcrumb.find(obj => {
    return obj.path === location.pathname;
  });

  useEffect(() => {
    copyTooltipRef?.current?.updateTargetEvents();
  }, [location]);

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide();
    }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!notificationMenuClick) {
      setTopbarNotificationMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    searchClick = false;
    configClick = false;
    userMenuClick = false;
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuitemClick = event => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isHorizontal()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive(prevMenuActive => !prevMenuActive);
  };

  const onTopbarUserMenuButtonClick = event => {
    userMenuClick = true;
    setTopbarUserMenuActive(
      prevTopbarUserMenuActive => !prevTopbarUserMenuActive,
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarNotificationMenuButtonClick = event => {
    notificationMenuClick = true;
    setTopbarNotificationMenuActive(
      prevTopbarNotificationMenuActive => !prevTopbarNotificationMenuActive,
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const toggleSearch = () => {
    setSearchActive(prevSearchActive => !prevSearchActive);
    searchClick = true;
  };

  const onSearchClick = () => {
    searchClick = true;
  };

  const onSearchHide = () => {
    setSearchActive(false);
    searchClick = false;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
          'gi',
        ),
        ' ',
      );
    }
  };

  const isHorizontal = () => {
    return true;
  };

  const containerClassName = classNames(
    'layout-wrapper',
    {
      'layout-horizontal': 'horizontal',
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'p-input-filled': inputStyle === 'filled',
    },
    colorScheme === 'light' ? menuTheme : '',
  );
  const { isLoading } = useLoader();

  const toggleLeftMenu = () => {
    setToggleLeftBar(!toggleLeftBar);
  };

  return (
    <div
      className={containerClassName}
      data-theme={colorScheme}
      onClick={onDocumentClick}
    >
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />
      <div className="">
        <div className="wrapper main-wrappper">
          <AppTopbar
            meta={meta}
            topbarNotificationMenuActive={topbarNotificationMenuActive}
            topbarUserMenuActive={topbarUserMenuActive}
            onSearchClick={toggleSearch}
            onTopbarNotification={onTopbarNotificationMenuButtonClick}
            onTopbarUserMenu={onTopbarUserMenuButtonClick}
            menu={menu}
            menuMode={'horizontal'}
            menuActive={menuActive}
            staticMenuMobileActive={staticMenuMobileActive}
            onMenuClick={onMenuClick}
            onMenuitemClick={onMenuitemClick}
            onRootMenuitemClick={onRootMenuitemClick}
            toggleLeftMenu={toggleLeftMenu}
          />

          <LeftSidebar toggleLeftBar={toggleLeftBar} />
          <div class={`main ${!toggleLeftBar ? 'active' : ''}`}>
            {isLoading && <ProgressBar />}
            <Outlet />
          </div>
        </div>
      </div>
      <AppSearch
        searchActive={searchActive}
        onSearchClick={onSearchClick}
        onSearchHide={onSearchHide}
      />
    </div>
  );
};

export default App;
