import { LoaderContext } from "providers/LoaderProvider"
import { useContext } from "react"

const useLoader = () => {
  const { setLoading, isLoading, loadingPercent, setLoadingPercent } = useContext(LoaderContext)

  return { setLoading, isLoading, loadingPercent, setLoadingPercent }
}

export default useLoader
