const MailIcon = (props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.66699 5.8335L8.47109 10.5964C9.02207 10.982 9.29756 11.1749 9.59721 11.2496C9.8619 11.3156 10.1387 11.3156 10.4034 11.2496C10.7031 11.1749 10.9786 10.982 11.5296 10.5964L18.3337 5.8335M5.66699 16.6668H14.3337C15.7338 16.6668 16.4339 16.6668 16.9686 16.3943C17.439 16.1547 17.8215 15.7722 18.0612 15.3018C18.3337 14.767 18.3337 14.067 18.3337 12.6668V7.3335C18.3337 5.93336 18.3337 5.2333 18.0612 4.69852C17.8215 4.22811 17.439 3.84566 16.9686 3.60598C16.4339 3.3335 15.7338 3.3335 14.3337 3.3335H5.66699C4.26686 3.3335 3.5668 3.3335 3.03202 3.60598C2.56161 3.84566 2.17916 4.22811 1.93948 4.69852C1.66699 5.2333 1.66699 5.93336 1.66699 7.3335V12.6668C1.66699 14.067 1.66699 14.767 1.93948 15.3018C2.17916 15.7722 2.56161 16.1547 3.03202 16.3943C3.5668 16.6668 4.26686 16.6668 5.66699 16.6668Z"
      stroke-width="1.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
      {...props}
    />
  </svg>
)

export default MailIcon
