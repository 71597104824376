import { closingMemoIcon } from 'components/Icons/ClosingMemos';
import { settingIcon } from 'components/Icons/Setting';
import { supportIcon } from 'components/Icons/Support';
import { teamIcon } from 'components/Icons/Team';
import transactionIcon from 'components/Icons/Transaction';
import { Link, useLocation } from 'react-router-dom';

export const LeftSidebar = props => {
  const { toggleLeftBar } = props;
  const { pathname } = useLocation();

  return (
    <aside id="sidebar" class={toggleLeftBar ? 'expand' : ''}>
      <div className="p-4 pb-2">
        <Link to="/">
          <img src="/assets/icons/logo.svg" alt="Company Logo" />
        </Link>
      </div>
      <ul className="sidebar-nav mt-3">
        <li className="sidebar-item">
          <Link
            to="/transactions"
            className={`sidebar-link ${
              pathname === '/transactions' || pathname === '/' ? 'active' : ''
            }`}
          >
            {transactionIcon}
            <span>Transactions</span>
          </Link>
        </li>
        {/* <li className="sidebar-item">
          <a
            href="#"
            target="_self"
            className={`sidebar-link ${
              pathname === '/organizations' ? 'active' : ''
            }`}
          >
            {organizationIcon}
            <span>Organizations</span>
          </a>
        </li> */}
        <li className="sidebar-item">
          <Link
            to="/closingmemos"
            className={`sidebar-link ${
              pathname === '/closingmemos' ? 'active' : ''
            }`}
          >
            {closingMemoIcon}
            <span>Closing Memos</span>
          </Link>
        </li>

        <li className="sidebar-item">
          <Link
            to="/team"
            className={`sidebar-link ${pathname === '/team' ? 'active' : ''}`}
          >
            {teamIcon}
            <span>Team</span>
          </Link>
        </li>
      </ul>
      <div className="sidebar-footer">
        <Link
          to="/support"
          className={`sidebar-link ${pathname === '/support' ? 'active' : ''}`}
        >
          {supportIcon}
          <span>Support</span>
        </Link>
        <span aria-hidden="true"> </span>
        <Link
          to="/settings"
          className={`sidebar-link ${pathname === '/settings' ? 'active' : ''}`}
        >
          {settingIcon}
          <span>Settings</span>
        </Link>
      </div>
    </aside>
  );
};
